export const breakpoints = {
  small: '0px',
  medium: '640px',
  large: '1000px',
  xlarge: '1200px',
  xxlarge: '1440px',
  wide: '1600px'
}

const theme = {
  breakpoints,
}

export default theme
