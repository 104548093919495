import { createGlobalStyle } from 'styled-components'
import { remCalc } from 'shared/utils/mixins'
import { down, up } from 'styled-breakpoints'

const GlobalStyles = createGlobalStyle`
:root {
  --color-black: #000000;
  --color-white: #FFFFFF;
  --color-dark-gray: #8B8B8B;

  --font-headline: 'Archivo', sans-serif;
  --font-body: 'Archivo', sans-serif;

  --gutter: 25px;

  --font-size-headline-1: 2.5rem;
  --font-size-main: 14px;
  --font-size-small: 12px;

  --line-height-heading: 0.98;
  --line-height-body: 145%;


  --border-width: 1px;

  --transition-hover: 0.3s ease;

  ${up('medium')} {
    --gutter: ${remCalc(50)};
    --font-size-headline-1: ${remCalc(20)};
    --font-size-main: ${remCalc(14)};
    --font-size-small: ${remCalc(10)};
  }

  ${up('large')} {
    --gutter: ${remCalc(75)};
    --font-size-headline-1: ${remCalc(20)};
    --border-width: 1.5px;
  }

  ${up('xxlarge')} {
    --gutter: ${remCalc(75)};
    --font-size-headline-1: ${remCalc(20)};
    --font-size-main: ${remCalc(13.5)};
    --font-size-small: ${remCalc(10.5)};
  }

  --half-gutter: calc(var(--gutter) / 2)
}

html {
  font-size: 16px;

  ${up('xlarge')} {
    font-size: 16px;
  }

  ${up('xxlarge')} {
    font-size: 1vw;
  }

  ${up('wide')} {
    font-size: 18px;
  }
}

body {
  background-color: var(--color-white);
  font-family: var(--font-body);
  font-size: var(--font-size-main);
}

.scroll-lock {
  overflow: hidden;
}

a {
  color: var(--color-black);
  transition: var(--transition-hover);

  &:hover {
    color: var(--color-dark-gray);
  }
}

h1,
h2,
h3,
h4 {
  font-family: var(--font-headline);
  line-height: var(--line-height-heading);
}

h1 {
  font-size: var(--font-size-headline-1);
}
h2 {
  font-size: var(--font-size-headline-1);
}

h3 {
  font-size: var(--font-size-headline-1);
}

h4 {
  font-size: var(--font-size-headline-1);
}

h5 {
  font-size: var(--font-size-headline-1);
  line-height: var(--line-height-body);
}

h6 {
  font-size: var(--font-size-main);
  font-weight: bold;
  line-height: var(--line-height-body);
}

p,
ol,
ul {
  font-size: var(--font-size-main);
  line-height: var(--line-height-body);
}

figcaption {
  font-size: var(--font-size-small);
  line-height: 1.2;
}

blockquote p {
  font-family: var(--font-headline);
  font-size: var(--font-size-headline-1);
  line-height: var(--line-height-heading);
  text-transform: uppercase;

  ${up('medium')} {
    font-size: var(--font-size-headline-1);
  }
}

blockquote cite {
  font-size: var(--font-size-small);
  line-height: 1.2;
  font-style: normal;
  display: inline-block;
}

* {
  &:focus {
    outline: none;
  }
}

input {
  &:focus {
    outline: none;
    border-color: var(--color-dark-gray);
  }
}

.embed iframe {
  width: 100%;
  height: 500px;

  ${down('small')} {
    height: 200px;
  }
}

`

export default GlobalStyles
