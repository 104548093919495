export function remCalc(value, baseValue = 16) {
  return `${value / baseValue}rem`
}

export function grid(ratio) {
  const percentage = ratio * 100

  return `
    width: 100%;
    flex: 0 0 ${percentage}%;
    max-width: ${percentage}%;
  `
}

export function Row(ratio) {
  return `
    width: 100%;
    display: flex;
    flex-flow: row wrap;
  `
}


