import { AppProps } from 'next/app'
import GlobalStyles from 'styles/GlobalStyles'

import 'normalize.css'
import '../styles/global.css'
import theme from 'shared/theme'
import React from 'react'
import { ThemeProvider } from 'styled-components'

import type {
  ExternalProvider,
  JsonRpcFetchFunc,
} from '@ethersproject/providers'
import { Web3Provider } from '@ethersproject/providers'
import { Web3ReactProvider } from '@web3-react/core'

function getLibrary(provider: ExternalProvider | JsonRpcFetchFunc) {
  return new Web3Provider(provider)
}

if (typeof window !== 'undefined' && !window.ResizeObserver) {
  (async () => {
    window.ResizeObserver = (await import('resize-observer-polyfill')).default
  })()
}

export default function MyApp({ Component, pageProps }: AppProps): React.ReactNode {
  return (
    <ThemeProvider theme={theme}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <GlobalStyles />
        <Component {...pageProps} />
      </Web3ReactProvider>
    </ThemeProvider>
  )
}
